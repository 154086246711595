import { ApiClientAddons } from '@/services/RestApiClientFactory/modules/ApiClientAddons';
import { ApiClientAnalytics } from '@/services/RestApiClientFactory/modules/ApiClientAnalytics/ApiClientAnalytics';
import { ApiClientApprovals } from '@/services/RestApiClientFactory/modules/ApiClientApprovals/ApiClientApprovals';
import { ApiClientAssistant } from '@/services/RestApiClientFactory/modules/ApiClientAssistant/ApiClientAssistant';
import { ApiClientAuth } from '@/services/RestApiClientFactory/modules/ApiClientAuth/ApiClientAuth';
import { ApiClientCancelData } from '@/services/RestApiClientFactory/modules/ApiClientCancelData/ApiClientCancelData';
import { ApiClientCurrency } from '@/services/RestApiClientFactory/modules/ApiClientCurrency/ApiClientCurrency';
import {
  ApiClientCustomReport,
} from '@/services/RestApiClientFactory/modules/ApiClientCustomReport/ApiClientCustomReport';
import { ApiClientEntries } from '@/services/RestApiClientFactory/modules/ApiClientEntries/ApiClientEntries';
import { ApiClientExpense } from '@/services/RestApiClientFactory/modules/ApiClientExpense/ApiClientExpense';
import { ApiClientExporter } from '@/services/RestApiClientFactory/modules/ApiClientExporter/ApiClientExporter';
import { ApiClientFavorites } from '@/services/RestApiClientFactory/modules/ApiClientFavorites/ApiClientFavorites';
import {
  ApiClientFrontendConfig,
} from '@/services/RestApiClientFactory/modules/ApiClientFrontendConfig/ApiClientFrontendConfig';
import { ApiClientGroup } from '@/services/RestApiClientFactory/modules/ApiClientGroup/ApiClientGroup';
import { ApiClientGuard } from '@/services/RestApiClientFactory/modules/ApiClientGuard/ApiClientGuard';
import { ApiClientHelpdesk } from '@/services/RestApiClientFactory/modules/ApiClientHelpdesk/ApiClientHelpdesk';
import {
  ApiClientIntegrations,
} from '@/services/RestApiClientFactory/modules/ApiClientIntegrations/ApiClientIntegrations';
import { ApiClientJiraV2 } from '@/services/RestApiClientFactory/modules/ApiClientJiraV2/ApiClientJiraV2';
import { ApiClientKiosk } from '@/services/RestApiClientFactory/modules/ApiClientKiosk/ApiClientKiosk';
import { ApiClientMainMenu } from '@/services/RestApiClientFactory/modules/ApiClientMainMenu/ApiClientMainMenu';
import { ApiClientModules } from '@/services/RestApiClientFactory/modules/ApiClientModules/ApiClientModules';
import { ApiClientRateType } from '@/services/RestApiClientFactory/modules/ApiClientRateType/ApiClientRateType';
import {
  ApiClientRemoteWorkDetection,
} from '@/services/RestApiClientFactory/modules/ApiClientRemoteWorkDetection/ApiClientRemoteWorkDetection';
import { ApiClientReport } from '@/services/RestApiClientFactory/modules/ApiClientReport/ApiClientReport';
import { ApiClientSession } from '@/services/RestApiClientFactory/modules/ApiClientSession/ApiClientSession';
import { ApiClientStorage } from '@/services/RestApiClientFactory/modules/ApiClientStorage/ApiClientStorage';
import { ApiClientSurvey } from '@/services/RestApiClientFactory/modules/ApiClientSurvey/ApiClientSurvey';
import { ApiClientTagList } from '@/services/RestApiClientFactory/modules/ApiClientTagList/ApiClientTagList';
import { ApiClientTasks } from '@/services/RestApiClientFactory/modules/ApiClientTasks/ApiClientTasks';
import { ApiClientTimeEntries } from '@/services/RestApiClientFactory/modules/ApiClientTimeEntries/ApiClientTimeEntries';
import {
  ApiClientTimeTracking,
} from '@/services/RestApiClientFactory/modules/ApiClientTimeTracking/ApiClientTimeTracking';
import { ApiClientTimer } from '@/services/RestApiClientFactory/modules/ApiClientTimer/ApiClientTimer';
import {
  ApiClientTrelloPowerUp,
} from '@/services/RestApiClientFactory/modules/ApiClientTrelloPowerUp/ApiClientTrelloPowerUp';
import { ApiClientUser } from '@/services/RestApiClientFactory/modules/ApiClientUser/ApiClientUser';
import { ApiClientUsers } from '@/services/RestApiClientFactory/modules/ApiClientUsers/ApiClientUsers';
import type { ConfigInterface } from '@/services/interfaces';

export class RestApiClient {
  config: Pick<ConfigInterface, 'baseUrl'>;
  reports: ApiClientReport;
  helpdesk: ApiClientHelpdesk;
  entries: ApiClientEntries;
  mainMenu: ApiClientMainMenu;
  tags: ApiClientTagList;
  tasks: ApiClientTasks;
  favorites: ApiClientFavorites;
  users: ApiClientUsers;
  user: ApiClientUser;
  survey: ApiClientSurvey;
  approvals: ApiClientApprovals;
  auth: ApiClientAuth;
  cancelData: ApiClientCancelData;
  session: ApiClientSession;
  timeTracking: ApiClientTimeTracking;
  group: ApiClientGroup;
  rateType: ApiClientRateType;
  integrations: ApiClientIntegrations;
  assistant: ApiClientAssistant;
  timeEntries: ApiClientTimeEntries;
  kiosk: ApiClientKiosk;
  analytics: ApiClientAnalytics;
  timer: ApiClientTimer;
  frontendConfig: ApiClientFrontendConfig;
  expense: ApiClientExpense;
  currency: ApiClientCurrency;
  storage: ApiClientStorage;
  guard: ApiClientGuard;
  modules: ApiClientModules;
  exporter: ApiClientExporter;
  customReport: ApiClientCustomReport;
  trelloPowerUp: ApiClientTrelloPowerUp;
  addons: ApiClientAddons;
  remoteWorkDetection: ApiClientRemoteWorkDetection;
  jiraV2: ApiClientJiraV2;

  constructor(config: Pick<ConfigInterface, 'baseUrl'>) {
    this.config = config;
    this.reports = new ApiClientReport(config);
    this.helpdesk = new ApiClientHelpdesk(config);
    this.entries = new ApiClientEntries(config);
    this.mainMenu = new ApiClientMainMenu(config);
    this.tags = new ApiClientTagList(config);
    this.tasks = new ApiClientTasks(config);
    this.favorites = new ApiClientFavorites(config);
    this.users = new ApiClientUsers(config);
    this.user = new ApiClientUser(config);
    this.approvals = new ApiClientApprovals(config);
    this.survey = new ApiClientSurvey(config);
    this.auth = new ApiClientAuth(config);
    this.cancelData = new ApiClientCancelData(config);
    this.session = new ApiClientSession(config);
    this.timeTracking = new ApiClientTimeTracking(config);
    this.group = new ApiClientGroup(config);
    this.rateType = new ApiClientRateType(config);
    this.integrations = new ApiClientIntegrations(config);
    this.timeEntries = new ApiClientTimeEntries(config);
    this.kiosk = new ApiClientKiosk(config);
    this.analytics = new ApiClientAnalytics(config);
    this.timer = new ApiClientTimer(config);
    this.assistant = new ApiClientAssistant(config);
    this.frontendConfig = new ApiClientFrontendConfig(config);
    this.expense = new ApiClientExpense(config);
    this.currency = new ApiClientCurrency(config);
    this.storage = new ApiClientStorage(config);
    this.guard = new ApiClientGuard(config);
    this.modules = new ApiClientModules(config);
    this.exporter = new ApiClientExporter(config);
    this.customReport = new ApiClientCustomReport(config);
    this.trelloPowerUp = new ApiClientTrelloPowerUp(config);
    this.addons = new ApiClientAddons(config);
    this.remoteWorkDetection = new ApiClientRemoteWorkDetection(config);
    this.jiraV2 = new ApiClientJiraV2(config);
  }
}
