import { ApiClientBase } from '@/services/RestApiClientFactory/modules/ApiClientBase';
import type { JiraV2SaveSettingsParams } from '@/services/RestApiClientFactory/modules/ApiClientJiraV2/ApiClientJiraV2.types';

export class ApiClientJiraV2 extends ApiClientBase {
  basePath = 'addons/jira_V2';

  async save(params: JiraV2SaveSettingsParams) {
    const result = await this.fetchData({
      queryString: `${this.basePath}/save`,
      method: 'POST',
      body: params,
      apiVersion: 0,
    });

    if (!result.ok) {
      throw Error();
    }

    return result;
  }
}
