import delay from 'delay';
import { List } from 'immutable';
import { cancelled, put, select } from 'redux-saga/effects';
import { selectAllFilters, selectFiltersByName } from '@/redux/models/FilterModel/selectors';
import {
  archivedDictionary,
  FilterModule,
  FilterName,
  GroupByFilter,
  GroupByFilterDictionary,
  GroupByKeys,
  OrderByValues,
  OrdersName,
} from '@/redux/models/FilterModel/types';
import {
  dataIsLoadingUpdated,
  fetchGroupedSummaryReport,
  fetchGroupedSummaryReportMetaSuccess,
  fetchGroupedSummaryReportSuccess,
  fetchReportStatusUpdated,
  initSummaryReport,
  saveSummaryReportData,
  saveSummaryReportId,
  setReportStatus,
  summaryReportSort,
} from '@/redux/models/ReportModel/actions';
import { selectReportData } from '@/redux/models/ReportModel/selectors';
import {
  BackendReportStatus,
  Column,
  ReportModelStateFields,
  ReportType,
  SummaryReportStatus,
} from '@/redux/models/ReportModel/types';
import { fillItemsExpandStatusForSummaryReport } from '@/redux/models/ReportModel/utils';
import { checkTasksCohesion } from '@/redux/models/TaskModel/actions';
import { SummaryReportRowTypes } from '@/components/reports/SummaryReport/SummaryReportTable/SummaryReportRowTypes';
import { reportEntityDataProvider } from '@/services/instances/reportEntityDataProvider';
import { restApiClient } from '@/services/instances/restApiClient';
import { timeService } from '@/services/instances/timeService';
import { TIMEFRAME_DATE_FORMAT } from '@/utils/constants';
import type { PartialPayloadAction } from '@/types/reduxTypes';
import type { NestedListItem } from '@/types/types';

const REPORT_GENERATE_WAIT_TIME_MILLISECONDS = 5000;
const REPORT_INIT_RETRY_TIME_MILLISECONDS = 3000;
let errorStatus = 0;

const TYPE_KEY = 'type';
const INIT_PAYLOAD = {
  [TYPE_KEY]: ReportType.SUMMARY_REPORT,
};

export function* onFetchSummaryReport({ payload }: PartialPayloadAction<{
  timeFrame;
}>) {
  const controller = new AbortController();
  const { signal } = controller;
  try {
    if (payload) {
      yield put(fetchReportStatusUpdated(SummaryReportStatus.STARTING));

      if (!payload['fileDownload']) {
        yield put(dataIsLoadingUpdated(true));
      }

      const payloadToInit = { ...INIT_PAYLOAD };
      payloadToInit['timeFrameStart'] = timeService.dateToFormat(payload.timeFrame.start, TIMEFRAME_DATE_FORMAT);
      payloadToInit['timeFrameEnd'] = timeService.dateToFormat(payload.timeFrame.end, TIMEFRAME_DATE_FORMAT);

      if (payload[FilterName.BILLABLE] !== null) {
        payloadToInit[FilterName.BILLABLE] = payload[FilterName.BILLABLE];
      }

      if (payload[FilterName.INVOICED] !== null) {
        payloadToInit[FilterName.INVOICED] = payload[FilterName.INVOICED];
      }

      if (payload[FilterName.ARCHIVED_TASKS] !== null) {
        const archivedPayload = payload[FilterName.ARCHIVED_TASKS];
        payloadToInit[FilterName.ARCHIVED_TASKS] = archivedDictionary[archivedPayload];
      }

      if (payload[FilterName.APPROVAL_STATUS] !== null) {
        payloadToInit[FilterName.APPROVAL_STATUS] = payload[FilterName.APPROVAL_STATUS];
      }

      if (payload[FilterName.USERS].length > 0) {
        payloadToInit[FilterName.USERS] = payload[FilterName.USERS];
      }

      if (payload[FilterName.TASKS].length > 0) {
        payloadToInit[FilterName.TASKS] = payload[FilterName.TASKS];
      }

      if (payload[FilterName.TAGS].length > 0) {
        payloadToInit[FilterName.TAGS] = payload[FilterName.TAGS];
      }

      if (payload[FilterName.NOTE] !== null) {
        payloadToInit[FilterName.NOTE] = payload[FilterName.NOTE];
      }

      let waitMs = 0;

      const reportInitResponse = yield restApiClient
        .reports
        .initializeReport(signal, payloadToInit)
        .then((result) => result)
        .catch((error) => {
          errorStatus = Number(error.message);
        });

      do {
        if (errorStatus) {
          yield put(setReportStatus(errorStatus));
          yield put(dataIsLoadingUpdated(false));
          return;
        }

        yield delay(waitMs);
        waitMs = REPORT_INIT_RETRY_TIME_MILLISECONDS;
      } while (!reportInitResponse);

      const reportId = reportInitResponse.id;

      if (errorStatus) {
        yield put(setReportStatus(errorStatus));
        yield put(dataIsLoadingUpdated(false));
        return;
      }

      yield put(saveSummaryReportId(reportId));

      yield put(fetchReportStatusUpdated(SummaryReportStatus.WAITING));

      let backendStatusOfReport = null;
      waitMs = 0;
      do {
        const { data: statusResponse } = yield restApiClient.reports.fetchReportStatus(signal, reportId);

        if (!statusResponse) {
          console.warn('Error getting report status. Reinitializing report...');
          yield refreshReport();
          return;
        }

        backendStatusOfReport = statusResponse.id;

        yield delay(waitMs);
        waitMs = REPORT_GENERATE_WAIT_TIME_MILLISECONDS;
      } while (backendStatusOfReport !== BackendReportStatus.Generated);

      const metaData = yield restApiClient
        .reports
        .fetchMeta(signal, reportId, ReportType.SUMMARY_REPORT)
        .then((result) => result)
        .catch((error) => {
          errorStatus = Number(error.message);
        });
      yield put(fetchGroupedSummaryReportMetaSuccess({ metaData }));
      if (!metaData) {
        put(initSummaryReport(payload));
      }

      yield put(fetchReportStatusUpdated(SummaryReportStatus.FETCHING_GROUPED));

      const firstGroup = payload[FilterName.GROUP_BY][GroupByKeys.GROUP_1_LEVEL];
      const secondGroup = payload[FilterName.GROUP_BY][GroupByKeys.GROUP_2_LEVEL];

      yield put(fetchGroupedSummaryReport(
        { firstGroup, secondGroup },
      ));
    }
  } finally {
    if (yield cancelled()) {
      controller.abort('Fetch summary report cancelled');
    }
  }
}

export function* onFetchGroupedSummaryReport({ payload }: PartialPayloadAction<{
  firstGroup: string;
  secondGroup: string;
}>) {
  const controller = new AbortController();
  const { signal } = controller;

  try {
    const { firstGroup, secondGroup } = payload || {};

    const reportId = yield select(selectReportData, { field: ReportModelStateFields.SUMMARY_REPORT_ID });
    if (reportId === null) {
      return;
    }

    const params = {};

    if (firstGroup && firstGroup !== GroupByFilterDictionary[GroupByFilter.NONE]) {
      params[GroupByKeys.GROUP_1_LEVEL] = firstGroup;
    }

    if (secondGroup && secondGroup !== GroupByFilterDictionary[GroupByFilter.NONE]) {
      params[GroupByKeys.GROUP_2_LEVEL] = secondGroup;
    }

    const reportResponse = yield restApiClient.reports.fetchGroupedSummaryReport(signal, reportId, params);

    if (reportResponse === null) {
      yield refreshReport();
      return;
    }

    const resultData = reportResponse.grouped ?? [];

    yield put(fetchReportStatusUpdated(SummaryReportStatus.FETCHED_GROUPED));

    let data: List<NestedListItem> = List();
    resultData.forEach((item) => {
      data = data.push(item);
    });

    data = yield fillItemsExpandStatusForSummaryReport(data);

    const metaData = yield select(selectReportData, { field: ReportModelStateFields.REPORT_META });

    const { totalTime } = metaData;

    const timeOmittedInCurrentGrouping = totalTime - reportResponse.groupedDuration;
    if (timeOmittedInCurrentGrouping > 0) {
      data = data.push({
        id: -1,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        duration: timeOmittedInCurrentGrouping,
        type: SummaryReportRowTypes.NON_GROUPED,
        children: [],
        isExpanded: false,
      });
    }

    const reportData = {
      data,
      totalTime,
    };

    yield put(fetchReportStatusUpdated(SummaryReportStatus.EMPTY_STATUS));
    yield put(dataIsLoadingUpdated(false));

    yield put(fetchGroupedSummaryReportSuccess(
      { reportData },
    ));
  } finally {
    if (yield cancelled()) {
      controller.abort();
    }
  }
}

export function* onFetchGroupedSummaryReportSuccess() {
  yield put(checkTasksCohesion());

  yield put(summaryReportSort());
}

export function* onSummaryReportSort() {
  const { column, direction } = yield select(
    selectFiltersByName,
    { module: FilterModule.ORDERS, name: OrdersName.SUMMARY_ORDER },
  );

  const reportData = yield select(selectReportData, {
    field: ReportModelStateFields.REPORT_SUMMARY,
  });

  if (
    !reportData ||
    !reportData.data ||
    reportData.data.size === 0 ||
    !column
  ) {
    return;
  }

  reportData.data = sortReportData(reportData.data, column, direction);

  yield put(saveSummaryReportData({ reportData }));
}

export function* refreshReport() {
  const reportFilters = yield select<any>(
    selectAllFilters,
    { module: FilterModule.REPORTS },
  );
  yield put(initSummaryReport(reportFilters));
}

const sortReportData = (data, column: string, direction: OrderByValues) => {
  let sorted;

  if (Array.isArray(data)) {
    const orderFactor = direction === OrderByValues.DESC ? -1 : 1;
    sorted = data.sort((a, b) => {
      switch (column) {
        case Column.TRACKED_TIME: {
          return orderFactor * (a.duration - b.duration);
        }

        case Column.NAME: {
          let firstItemName;
          let secondItemName;
          try {
            firstItemName = reportEntityDataProvider.getItemName(a.id, a.type);
          } catch (e) {
            firstItemName = '';
          }
          try {
            secondItemName = reportEntityDataProvider.getItemName(b.id, b.type);
          } catch (e) {
            secondItemName = '';
          }

          if (firstItemName === '') {
            return orderFactor;
          }

          if (secondItemName === '') {
            return orderFactor * -1;
          }

          return orderFactor * firstItemName.localeCompare(secondItemName);
        }

        default: {
          return orderFactor;
        }
      }
    });

    sorted.forEach((element, index) => {
      if (sorted[index].children) {
        sorted[index].children = sortReportData(
          element.children,
          column,
          direction,
        );
      }
    });
  } else {
    switch (column) {
      case Column.TRACKED_TIME: {
        sorted = data.sortBy((item) => item.duration);
        sorted = direction === OrderByValues.DESC ? sorted.reverse() : sorted;
        break;
      }

      case Column.NAME: {
        const orderFactor = direction === OrderByValues.DESC ? -1 : 1;
        sorted = data.sort((a, b) => {
          let firstItemName;
          let secondItemName;
          try {
            firstItemName = reportEntityDataProvider.getItemName(a.id, a.type);
          } catch (e) {
            firstItemName = '';
          }
          try {
            secondItemName = reportEntityDataProvider.getItemName(b.id, b.type);
          } catch (e) {
            secondItemName = '';
          }

          if (firstItemName === '') {
            return orderFactor;
          }

          if (secondItemName === '') {
            return orderFactor * -1;
          }
          return orderFactor * firstItemName.localeCompare(secondItemName);
        });
        break;
      }

      default: {
        sorted = data;
        break;
      }
    }

    const toUpdate = {};

    sorted.forEach((element, index) => {
      if (element.children && element.children.length) {
        toUpdate[index] = sortReportData(element.children, column, direction);
      }
    });

    sorted = sorted.withMutations((result) => {
      Object.keys(toUpdate).forEach((index) => {
        result = result.setIn([index, 'children'], toUpdate[index]);
      });

      return result;
    });
  }

  return sorted;
};
