import { List } from 'immutable';
import { getSummaryExpandStatuses } from '@/services/BrowserStorageService/partials/ReportStorageService';
import { ReportModelStateFields } from './types';

export const getDefaultValue = (name: ReportModelStateFields): unknown => {
  switch (name) {
    case ReportModelStateFields.REPORT_SUMMARY:
      return {
        totalTime: 0,
        data: List(),
      };
    case ReportModelStateFields.REPORT_DETAILED:
      return {
        totalTime: 0,
        data: List(),
      };
    case ReportModelStateFields.REPORT_STATUS:
      return null;
    case ReportModelStateFields.SUMMARY_REPORT_ID:
      return null;
    case ReportModelStateFields.DETAILED_REPORT_ID:
      return null;
    default:
      return null;
  }
};

export const fillItemsExpandStatusForSummaryReport = async (
  data: List<any>,
): Promise<List<any>> => {
  const fillChildren = (data, currentLeftNodes) => {
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      item.isExpanded = currentLeftNodes.includes(item.id);
      item.children = fillChildren(item.children, currentLeftNodes);
    }

    return data;
  };

  const expandStatuses = (await getSummaryExpandStatuses()) || [];
  data.forEach((item, i) => {
    item.isExpanded = expandStatuses.includes(item.id);
    item.children = fillChildren(item.children, expandStatuses);
    data.set(i, item);
  });

  return data;
};
