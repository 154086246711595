import { setDefaultOptions } from 'date-fns';
import { useEffect, useState } from 'react';
import { useTranslation } from '@/hooks/useTranslation';
import { LANGUAGE } from '@/types/types';
import type { Locale } from 'date-fns';

export const useLocaleDate = () => {
  const { i18n: { language } } = useTranslation();
  const [locale, setLocale] = useState<Locale>();

  useEffect(() => {
    const locale = async () => {
      let locale: Locale;

      switch (language) {
        case LANGUAGE.PL:
          locale = (await import('date-fns/locale/pl')).pl;
          break;
        case LANGUAGE.DE:
          locale = (await import('date-fns/locale/de')).de;
          break;
        case LANGUAGE.FR:
          locale = (await import('date-fns/locale/fr')).fr;
          break;
        case LANGUAGE.ES:
          locale = (await import('date-fns/locale/es')).es;
          break;
        case LANGUAGE.PT:
          locale = (await import('date-fns/locale/pt')).pt;
          break;
        default:
          locale = (await import('date-fns/locale/en-US')).enUS;
      }

      setDefaultOptions({ locale });
      setLocale(locale);
    };

    locale();
  }, [language]);

  return {
    locale,
  };
};
