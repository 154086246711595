import uniqueId from 'lodash/uniqueId';
import { createContext, useState } from 'react';
import type { INotifications } from '@/components/common/Notifications/types';
import type { Dispatch, PropsWithChildren } from 'react';

type ContextType = {
  alerts: INotifications[];
  dispatchNotification: Dispatch<INotifications>;
  dispatchNotificationRemove: Dispatch<string>;
};

export const NotificationsContext = createContext<ContextType>({
  alerts: [],
  dispatchNotification: () => undefined,
  dispatchNotificationRemove: () => undefined,
});

export const NotificationsProvider = ({ children }: PropsWithChildren) => {
  const [alerts, setAlerts] = useState<ContextType['alerts']>([]);

  const dispatchNotification = (alert: INotifications) => {
    setAlerts((state) => ([...state, { id: uniqueId(), ...alert }]));
  };

  const dispatchNotificationRemove = (alertId: string) => {
    setAlerts((state) => (state.filter(alert => alert.id !== alertId)));
  };

  return (
    <NotificationsContext.Provider value={{
      alerts,
      dispatchNotification,
      dispatchNotificationRemove,
    }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};
