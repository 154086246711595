import type { Analytics } from '@/services/Analitics/Analytics';

export enum EntryActions {
  DELETE_ENTRY = 'DELETE_ENTRY',
  DELETE_ENTRY_SUCCESS = 'DELETE_ENTRY_SUCCESS',
  EDIT_ENTRY = 'EDIT_ENTRY',
  EDIT_ENTRY_SUCCESS = 'EDIT_ENTRY_SUCCESS',
  EDIT_ENTRY_TAG = 'EDIT_ENTRY_TAG',
  EDIT_ENTRY_TAG_SUCCESS = 'EDIT_ENTRY_TAG_SUCCESS',
}

export type Entry = {
  id: number,
  startTime: string | undefined,
  endTime: string | undefined,
  duration: number | undefined,
  date: string | undefined,
  note: string | undefined | null,
  description: string | undefined,
  invoiceId: number | undefined,
  taskId: number | undefined,
  billable: boolean | undefined,
  analytics: Analytics | undefined,
};

export type BackendEntry = {
  id: number,
  start_time: string | undefined,
  end_time: string | undefined,
  duration: number | undefined,
  date: string | undefined,
  note: string | undefined | null,
  description: string | undefined,
  invoiceId: number | undefined,
  task_id: number | undefined,
  billable: boolean | undefined,
};
